import './index.scss';
import LogoTitle from '../../assets/images/logo-p.png';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import AnimatedLetters from '../AnimatedLetters';
import Logo from './Logo';
import Loader from 'react-loaders';

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const nameArray = ['a','b','l','o',','];
    const jobArray = ['d','e','s','a','r','r','o','l','l','a','d','o','r',' ','w','e','b','.'];

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 4000);
    }, []);
    
    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>o</span>
                    <span className={`${letterClass} _13`}>l</span>
                    <span className={`${letterClass} _14`}>a</span>
                    <span className={`${letterClass} _15`}>,</span>
                    <br/>
                    <span className={`${letterClass} _16`}>s</span> 
                    <span className={`${letterClass} _17`}>o</span> 
                    <span className={`${letterClass} _18`}>y</span> 
                    <img src={LogoTitle} alt="developer"/>
                    <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={19}/>
                    <br />
                    <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={18}/>
                    </h1>
                    <h2 style={{fontSize: "14px"}}>Desarrollador Web / Programador</h2>
                    <Link to="/contact" className='flat-button'>HABLEMOS</Link>
                </div>
                <Logo/>
            </div>
            <Loader type="semi-circle-spin" />  
        </>
    );
};

export default Home;