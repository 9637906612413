import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import {useState, useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLaravel, faCss3, faPhp, faPython, faJsSquare, faReact,} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    function downloadCV(){
        fetch('CV_PabloPovar.pdf').then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = 'CV_PabloPovar.pdf'
                alink.click()
            })
        })
    }

    return(
        <>
            <div className="container about-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['S', 'o', 'b', 'r', 'e', ' ', 'm', 'í']}
                        idx={15}
                        />
                    </h1>
                    <p id="about-text">
                        Técnico Superior en Desarrollo Web que trabaja actualmente con PHP y Laravel y tiene conocimientos en JavaScript, TypeScript, JQuery, React, Java, Python, Angular...
                        Curioso, autodidacta y con ganas de crear soluciones que resuelven problemas. Apasionado del entrenamiento de fuerza y la nutrición.
                    </p>
                    <div id="cv-button" onClick={() => {downloadCV()}} className='flat-button'>DESCARGAR CV</div>
                </div>
                <div className="stage-cube-cont">
                    <div className="cubespinner">
                        <div className="face1">
                        <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
                        </div>
                        <div className="face2">
                        <FontAwesomeIcon icon={faPython} color="#306998"/>
                        </div>
                        <div className="face3">
                        <FontAwesomeIcon icon={faCss3} color="#28A4D9"/>
                        </div>
                        <div className="face4">
                        <FontAwesomeIcon icon={faReact} color="#5ED4F4"/>
                        </div>
                        <div className="face5">
                        <FontAwesomeIcon icon={faLaravel} color="#FF0000"/>
                        </div>
                        <div className="face6">
                        <FontAwesomeIcon icon={faPhp} color="#7A86B8"/>
                        </div>
                    </div>
                </div>
            </div>
            <Loader type="semi-circle-spin" />  
        </>      
    )
}

export default About