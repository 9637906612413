import {useState , useEffect } from "react"
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

import LogoMarco from '../../assets/images/LOGO_DOC_00_GRIS50_75-copia-2.png'
import LogoA2U from '../../assets/images/logotipoa2u.png'

const Career = () => {

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const timelineItems = [
        {
            date: 'Marzo 2023 - Actualidad',
            title: 'Programador web fullstack',
            location: 'Advisor2you (a2U), Zaragoza',
            content: 'Analizando problemas y desarrollando soluciones para los mismos en Laravel (PHP/JS/JQuery/MySQL/PostgreSQL, integraciones con React/Python). CRM, aplicación para economistas, etc. DevOps con Azure y Git.',
        },
        {
            date: 'Septiembre 2021 - Junio 2023',
            title: 'Ciclo superior de técnico en desarrollo de aplicaciones web',
            location: 'Marco Formación, Zaragoza',
        },        
    ]

    let icons = [
        <img style={{ width: '100%' }} src={LogoA2U} alt="Logo a2U" />,
        <img style={{ width: '100%' }} src={LogoA2U} alt="Logo a2U"></img>,
        <img style={{ width: '100%' }} src={LogoMarco} alt="Logo Marco Formación"></img>
    ]

    return(
        <>
            <div className="container career-page">
                <div className="text-zone" style={{top: "25%"}}>
                    <h1>
                        <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['M', 'i', ' ', 't', 'r', 'a', 'y', 'e', 'c', 't', 'o', 'r', 'i', 'a']}
                        idx={15}
                        />
                    </h1>
                </div>
                <div className="timeline-container">
                    <VerticalTimeline lineColor = {'#4d4d4e'}>
                        {timelineItems.map((item, index) => (
                            <VerticalTimelineElement
                                key = {index}
                                date = {<div>{item.date}</div>}
                                contentStyle = {{ background: index%2===0?'#181818':"#181818", color:"#708eb4"}}
                                contentArrowStyle = {{ borderRight: '7px solid #4d4d4e' }}
                                iconStyle = {{ background: "transparent", color: "#4d4d4e", border: "2px solid #4d4d4e", padding: "16px", marginRight: "4px" }}
                                icon = {icons[index]}                                
                            >
                            <h3 className="vertical-timeline-element-title">{item.title}</h3>
                            <h4 className="vertical-timeline-element-subtitle">{item.location}</h4>
                            <p style={{fontSize: "12px"}}>{item.content}</p>
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>
            </div>
        </>
    )
}

export default Career