import './index.scss'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { faJs, faReact, faLaravel, faAngular, faSass } from '@fortawesome/free-brands-svg-icons'

import calcScreen from '../../assets/images/calcscreen.png'
import weatherScreen from '../../assets/images/weatherscreen.png'
import printerScreen from '../../assets/images/3dealityscreen.png'
import foodScreen from '../../assets/images/foodscreen.png'
import complexScreen from '../../assets/images/complexscreen.png'

import calcScreenMobile from '../../assets/images/calcscreen-mobile.jpg'
import weatherScreenMobile from '../../assets/images/weatherscreen-mobile.jpg'
import printerScreenMobile from '../../assets/images/3dealityscreen-mobile.png'
import foodScreenMobile from '../../assets/images/foodscreen-mobile.jpg'
import complexScreenMobile from '../../assets/images/complexscreen-mobile.jpg'

const Portfolio = () => {

    const [width, setWidth] = useState(window.innerWidth)
    const breakpoint = 1200

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth)        
        window.addEventListener("resize", handleResizeWindow)        
        return () => {
            window.removeEventListener("resize", handleResizeWindow)       
        }
    },[])

    const [letterClass, setLetterClass] = useState('text-animate')

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover')
        }, 3000)
    }, [])

    const [badgeText, setBadgeText] = useState('');
    const [webHref, setWebHref] = useState('');
    const [dNone, setDNone] = useState('d-none');
    const [dNone2, setDNone2] = useState('d-none');  
    const [img, setImg] = useState(null);
    const [icons, setIcons] = useState([]);
    const [text, setText] = useState("");

    const colorMap = {
        faJs: '#f7df1e',
        faReact: '#61dafb',
        faAngular: '#dd1b16',
        faLaravel: '#ff2d20',
        faSass: '#cc6699',
    };

    const textMap = {
        calcScreen: 'Mi primer proyecto de programación web. Es una aplicación frontend sencilla hecha con HTML5/CSS3/JavaScript',
        weatherScreen: 'Se trata de una SPA (Single Page Application) que consume una API open source de OpenWeather para mostrar el tiempo meteorológico a 5 días de cualquier lugar. El lugar puede cambiarse de tu localización actual a cualquier punto del mapa interactivo de la biblioteca Leaflet. Esta aplicación está creada con React y usa preprocesamiento CSS de SASS.',
        printerScreen: 'Fue mi proyecto de fin de grado. Es un MVP (producto mínimo viable) de e-commerce, tematizado de impresión 3D. El front está implementado en React y el back en Laravel. Utiliza una base de datos MySQL.',
        foodScreen: 'Es una SPA hecha con React que consume la API de nutrición de EdamamAPI y la de traducción de Google. Utiliza el framework de estilos PicoCSS.',
        complexScreen: 'Una calculadora de operaciones con números complejos. Es una SPA hecha con Angular y PicoCSS.',
        calcScreenMobile: 'Mi primer proyecto de programación web. Es una aplicación frontend sencilla hecha con HTML5/CSS3/JavaScript',
        weatherScreenMobile: 'Se trata de una SPA (Single Page Application) que consume una API open source de OpenWeather para mostrar el tiempo meteorológico a 5 días de cualquier lugar. El lugar puede cambiarse de tu localización actual a cualquier punto del mapa interactivo de la biblioteca Leaflet. Esta aplicación está creada con React y usa preprocesamiento CSS de SASS.',
        printerScreenMobile: 'Fue mi proyecto de fin de grado. Es un MVP (producto mínimo viable) de e-commerce, tematizado de impresión 3D. El front está implementado en React y el back en Laravel. Utiliza una base de datos MySQL.',
        foodScreenMobile: 'Es una SPA hecha con React que consume la API de nutrición de EdamamAPI y la de traducción de Google. Utiliza el framework de estilos PicoCSS.',
        complexScreenMobile: 'Una calculadora de operaciones con números complejos. Es una SPA hecha con Angular y PicoCSS.',
    };

    useEffect(() => {
        const iconMap = {
            faJs: faJs,
            faReact: faReact,
            faAngular: faAngular,
            faLaravel: faLaravel,
            faSass: faSass,
        };

        const imgMap = {
            calcScreen: calcScreen,
            weatherScreen: weatherScreen,
            printerScreen: printerScreen,
            foodScreen: foodScreen,
            complexScreen: complexScreen,
            calcScreenMobile: calcScreenMobile,
            weatherScreenMobile: weatherScreenMobile,
            printerScreenMobile: printerScreenMobile,
            foodScreenMobile: foodScreenMobile,
            complexScreenMobile: complexScreenMobile,
        };        

        const badges = document.querySelectorAll('.web-badge');

        const handleClick = (event) => {
            setBadgeText(event.currentTarget.innerText);
            setWebHref(event.currentTarget.getAttribute('data-href'));
            setDNone('');

            const imgName = event.currentTarget.getAttribute('data-img');
            setImg(imgMap[imgName] || null);
            setDNone2(imgMap[imgName] ? '' : 'd-none');
            setText(textMap[imgName]);

            const stack = event.currentTarget.getAttribute('data-stack').split(', ');
            const stackIcons = stack.map(icon => ({
                icon: iconMap[icon],
                color: colorMap[icon],
            }));
            setIcons(stackIcons);
        };

        badges.forEach(badge => {
            badge.addEventListener('click', handleClick);
        });

        return () => {
            badges.forEach(badge => {
                badge.removeEventListener('click', handleClick);
            });
        };
    }, []);


    if(width>breakpoint){
        return(
            <>
                <div className="container portfolio-page">
                    <div className="text-zone" style={{top: "25%"}}>
                        <h1>
                            <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['M', 'i', ' ', 'p', 'o', 'r', 't', 'f', 'o', 'l', 'i', 'o']}
                            idx={15}
                            />
                        </h1>
                    </div>
                    <div className="portfolio-flex-container">
                        <div className="webs-display">
                            <div id="calc" data-count="calcCount" data-img="calcScreen" data-stack="faJs" data-href="https://calculadoraprogweb.netlify.app" className="web-badge">
                                <div>Calculadora de programador</div>
                            </div>
                            <div id="weather" data-count="weatherCount" data-img="weatherScreen" data-stack="faReact, faSass" data-href="https://pablopg-weatherapp.netlify.app/" className="web-badge">
                                <div>WeatherApp</div>
                            </div>
                            <div id="printer" data-count="printerCount" data-img="printerScreen" data-stack="faReact, faLaravel" data-href="https://3deality.netlify.app/" className="web-badge">
                                <div>3Deality</div>
                            </div>
                            <div id="food" data-count="foodCount" data-img="foodScreen" data-stack="faReact" data-href="https://pablopg-foodsearch.netlify.app/" className="web-badge">
                                <div>FoodSearch</div>
                            </div>
                            <div id="complex" data-count="complexCount" data-img="complexScreen" data-stack="faAngular" data-href="https://calculadoracomplejos.netlify.app/" className="web-badge">
                                <div>Calculadora de complejos</div>
                            </div>
                        </div>
                        <div className="web-info">
                            <div className={`web-title ${dNone}`}>{badgeText} <a target="_blank" rel="noopener noreferrer" href={webHref}><FontAwesomeIcon className="web-link" icon={faArrowUpRightFromSquare} color={'#708eb4'}></FontAwesomeIcon></a></div>
                            <div className="web-icons">
                                {icons.map((icon, index) => (
                                    <FontAwesomeIcon key={index} icon={icon.icon} color={icon.color} />
                                ))}
                            </div>
                            <div className="web-text">{text}</div>
                            <div className={`web-pic-container ${dNone2}`}><img src={img}></img></div>
                        </div>
                    </div>
                </div>  
                <Loader type="semi-circle-spin"/>          
            </>
        );
    }else{
        return(
            <>
                <div className="container portfolio-page">
                    <div className="text-zone" style={{top: "25%"}}>
                        <h1>
                            <AnimatedLetters
                            letterClass={letterClass}
                            strArray={['M', 'i', ' ', 'p', 'o', 'r', 't', 'f', 'o', 'l', 'i', 'o']}
                            idx={15}
                            />
                        </h1>
                    </div>
                    <div className="portfolio-flex-container">
                        <div className="webs-display">
                            <div id="calc" data-count="calcCount" data-img="calcScreenMobile" data-stack="faJs" data-href="https://calculadoraprogweb.netlify.app" className="web-badge">
                                <div>Calculadora de programador</div>
                            </div>
                            <div id="weather" data-count="weatherCount" data-img="weatherScreenMobile" data-stack="faReact, faSass" data-href="https://pablopg-weatherapp.netlify.app/" className="web-badge">
                                <div>WeatherApp</div>
                            </div>
                            <div id="printer" data-count="printerCount" data-img="printerScreenMobile" data-stack="faReact, faLaravel" data-href="https://3deality.netlify.app/" className="web-badge">
                                <div>3Deality</div>
                            </div>
                            <div id="food" data-count="foodCount" data-img="foodScreenMobile" data-stack="faReact" data-href="https://pablopg-foodsearch.netlify.app/" className="web-badge">
                                <div>FoodSearch</div>
                            </div>
                            <div id="complex" data-count="complexCount" data-img="complexScreenMobile" data-stack="faAngular" data-href="https://calculadoracomplejos.netlify.app/" className="web-badge">
                                <div>Calculadora de complejos</div>
                            </div>
                        </div>
                        <div className="web-info">
                            <div className={`web-title ${dNone}`}>{badgeText} <a target="_blank" rel="noopener noreferrer" href={webHref}><FontAwesomeIcon className="web-link" icon={faArrowUpRightFromSquare} color={'#708eb4'}></FontAwesomeIcon></a></div>
                            <div className="web-icons">
                                {icons.map((icon, index) => (
                                    <FontAwesomeIcon key={index} icon={icon.icon} color={icon.color} />
                                ))}
                            </div>
                            <div className="web-text">{text}</div>
                            <div className={`web-pic-container ${dNone2}`}><img src={img}></img></div>
                        </div>
                    </div>
                </div>  
                <Loader type="semi-circle-spin"/>          
            </>
        );
    }
}

export default Portfolio