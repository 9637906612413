import './index.scss';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import {useState, useEffect, useRef} from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const refForm = useRef();

    useEffect(() => {
        setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
    }, []);
    
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1uy190m','template_elyae2m',refForm.current,'eidZ0O-FIPVIo3ejf')
        .then(
            () => {
                alert('Mensaje enviado con éxito. ');
                window.location.reload(false);
            },
            () => {
                alert('Se ha producido un error al enviar el mensaje. ');
            }
        );                
    }
   

    return(
        <>
            <div className="container contact-page">
                <div className="text-zone">
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['H','a','b','l','e','m','o','s']} idx={15}/>
                    </h1>
                </div>
                <div className="contact-form">
                    <form ref={refForm} onSubmit={sendEmail}>
                        <ul>
                            <div className="half">
                                <li>
                                    <input type="text" name="name" placeholder="Nombre" required></input>
                                </li>
                                <li>
                                    <input type="text" name="email" placeholder="e-Mail" required></input>
                                </li>
                            </div>
                            <li>
                                <input type="text" name="subject" placeholder="Asunto" required></input>
                            </li>
                            <li>
                                <textarea type="text" name="message" placeholder="Mensaje" required></textarea>
                            </li>
                            <li>
                                <input type="submit" className="flat-button" value="ENVIAR"></input>
                            </li>
                        </ul>
                    </form>
                </div>
                <div className="picture"></div>
            </div>
            <Loader type="semi-circle-spin"/>
        </>
    );
}

export default Contact;