import './index.scss';
import LogoP from '../../../assets/images/logo-p.png';
import {useRef,useEffect} from 'react';
import gsap from 'gsap-trial';

const Logo = () => {
    
    const bgRef = useRef();
    const outlineLogoRef = useRef();
    const solidLogoRef = useRef();

    useEffect(() => {    
        gsap.fromTo(
            solidLogoRef.current,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                delay: 4,
                duration: 4,
            }
        );
        gsap.fromTo(
            outlineLogoRef.current,
            {
                opacity: 1,
            },
            {
                opacity: 0,
                delay: 4,
                duration: 4,
            }
        );
    }, []);

    return(
        <div className="logo-container" ref={bgRef}>
            <img ref={solidLogoRef} className="solid-logo" src={LogoP} alt='P'/>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="600.000000pt" height="880.000000pt" viewBox="0 0 600.000000 880.000000"
            preserveAspectRatio="xMidYMid meet">
            <g className='svg-container' transform="translate(0.000000,880.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path ref={outlineLogoRef} d="M486 8518 c-9 -13 -51 -77 -93 -143 -42 -66 -89 -139 -105 -162 l-28
            -41 2 -3954 3 -3953 887 -3 887 -2 17 32 c9 18 41 70 71 116 29 45 53 85 53
            87 0 3 21 37 48 77 l47 71 3 1252 2 1252 338 7 c185 3 389 11 452 16 63 6 203
            17 311 25 108 9 218 20 245 25 208 37 380 73 389 80 5 4 48 18 95 30 46 12 89
            25 95 30 6 5 37 19 70 31 65 24 193 89 236 120 15 10 31 19 37 19 5 0 21 11
            36 25 15 14 30 25 33 25 20 0 207 171 311 284 70 76 182 217 182 230 0 3 29
            48 65 100 36 52 65 98 65 103 0 5 20 38 45 73 25 35 45 70 45 77 0 7 4 13 9
            13 5 0 20 21 33 47 14 27 47 89 72 138 57 109 99 205 133 305 13 41 29 84 33
            95 5 11 18 56 29 100 83 347 91 426 91 905 0 392 -10 549 -45 695 -8 33 -23
            98 -34 145 -36 155 -95 319 -178 490 -45 92 -56 112 -132 230 -154 242 -370
            462 -591 602 -220 140 -495 221 -955 283 -274 36 -346 38 -1827 42 l-1466 4
            -16 -23z m2705 -18 c311 -14 580 -36 709 -60 58 -10 139 -24 180 -30 68 -10
            145 -29 310 -76 139 -39 427 -195 480 -259 7 -8 17 -15 23 -15 6 0 25 -15 42
            -32 18 -18 56 -55 84 -83 75 -73 99 -97 124 -132 12 -17 50 -67 84 -110 150
            -194 301 -501 367 -748 28 -105 65 -273 75 -340 26 -181 43 -483 37 -655 -10
            -272 -33 -517 -56 -600 -5 -19 -22 -89 -36 -154 -14 -65 -30 -126 -35 -135 -4
            -9 -18 -47 -29 -84 -21 -74 -24 -80 -94 -231 -46 -99 -136 -259 -142 -253 -2
            2 14 58 36 125 21 67 48 165 60 218 11 54 23 100 25 104 3 4 14 78 25 164 17
            133 20 215 20 544 0 394 -10 551 -45 697 -8 33 -23 98 -34 145 -36 155 -95
            319 -178 490 -45 92 -56 112 -132 230 -71 113 -188 258 -286 356 -276 277
            -551 410 -1010 490 -440 76 -454 77 -1932 81 l-1323 4 0 180 0 179 1208 0
            c665 0 1314 -5 1443 -10z m-2681 -180 l0 -170 -117 0 c-65 0 -113 4 -108 8 6
            4 51 70 100 147 92 145 118 184 123 185 1 0 2 -76 2 -170z m0 -3924 l0 -3734
            -110 -173 -110 -172 0 3906 0 3907 110 0 110 0 0 -3734z m2265 3724 c99 -5
            275 -14 390 -21 291 -15 662 -63 800 -104 28 -8 91 -25 140 -39 150 -41 276
            -100 431 -202 237 -157 472 -431 633 -738 219 -419 315 -948 282 -1560 -12
            -222 -33 -423 -51 -486 -5 -19 -22 -89 -36 -154 -14 -65 -30 -126 -35 -135 -4
            -9 -18 -47 -29 -84 -11 -38 -26 -83 -34 -100 -83 -183 -81 -181 -182 -283
            -188 -191 -364 -308 -626 -415 -53 -21 -101 -39 -107 -39 -6 0 -45 -11 -87
            -25 -66 -22 -187 -48 -399 -86 -196 -35 -821 -69 -1272 -69 -253 0 -353 -3
            -353 -11 0 -6 -20 -41 -44 -78 -24 -36 -65 -100 -91 -141 -77 -122 -100 -155
            -108 -158 -4 -2 -7 -570 -7 -1263 l0 -1259 -725 0 -725 0 0 3730 0 3730 1028
            0 c565 0 1108 -4 1207 -10z m2314 -3972 c-44 -81 -210 -288 -301 -373 -67 -63
            -208 -173 -273 -214 -107 -65 -378 -191 -414 -191 -6 0 -45 -11 -87 -25 -66
            -22 -187 -48 -399 -86 -166 -29 -809 -69 -1127 -69 l-208 0 0 180 0 180 434 0
            c238 0 493 5 567 10 74 6 199 15 279 21 350 26 708 99 930 191 85 35 221 101
            259 127 14 10 58 39 96 64 39 26 111 83 160 127 50 43 91 79 93 80 1 0 -3 -10
            -9 -22z m-2852 -955 c-3 -2 -50 -2 -105 -1 l-101 3 102 160 102 159 3 -158 c1
            -87 1 -161 -1 -163z m3 -1268 c0 -679 1 -1238 3 -1242 1 -5 -46 -9 -105 -11
            l-108 -3 0 1246 0 1245 105 0 105 0 0 -1235z m-248 -1456 c2 -99 1 -183 -1
            -185 -2 -2 -386 -4 -852 -4 l-848 0 117 185 117 184 732 1 732 0 3 -181z m221
            139 c-63 -100 -128 -206 -147 -240 -12 -21 -24 -38 -28 -38 -5 0 -8 72 -8 160
            l0 160 105 0 105 0 -27 -42z"/>
            <path ref={outlineLogoRef} d="M2240 7240 c0 -5 -37 -67 -81 -137 -122 -191 -153 -238 -161 -241 -5
            -2 -8 -527 -8 -1168 l0 -1164 23 -6 c40 -10 727 3 816 15 197 27 342 73 451
            143 60 39 180 160 180 182 0 8 6 19 14 25 7 6 32 55 56 109 33 76 55 110 100
            157 32 33 68 76 79 95 46 76 103 209 122 280 11 41 24 82 29 92 5 9 17 70 27
            135 21 144 24 525 5 648 -30 190 -110 391 -208 521 -101 134 -178 195 -339
            265 -35 16 -147 38 -247 48 -125 14 -858 15 -858 1z m920 -45 c188 -30 286
            -73 391 -173 162 -152 270 -379 304 -640 27 -196 17 -536 -19 -697 -49 -219
            -143 -425 -226 -500 l-21 -19 7 29 c4 17 10 35 15 40 4 6 16 64 25 130 22 146
            25 527 6 650 -30 190 -110 391 -208 521 -101 134 -178 195 -339 265 -87 38
            -292 59 -580 59 l-235 0 0 181 0 181 388 -5 c296 -4 412 -9 492 -22z m-923
            -332 c-3 -2 -50 -2 -105 -1 l-101 3 62 96 c34 52 80 124 102 159 l40 64 3
            -158 c1 -88 1 -161 -1 -163z m3 -993 l0 -959 -22 -34 c-13 -18 -52 -80 -88
            -136 -36 -57 -73 -113 -82 -125 -16 -20 -17 39 -18 1097 l0 1117 105 0 105 0
            0 -960z m670 935 c187 -29 286 -73 391 -173 164 -154 270 -380 306 -650 27
            -210 7 -640 -36 -752 -6 -14 -15 -44 -21 -66 -13 -51 -54 -81 -181 -129 -87
            -34 -229 -66 -279 -63 -14 1 -68 -4 -120 -10 -52 -6 -229 -14 -392 -18 l-298
            -6 0 947 0 948 263 -6 c166 -4 300 -12 367 -22z m578 -1812 c-85 -183 -185
            -278 -367 -348 -169 -64 -337 -84 -771 -92 -278 -5 -323 -4 -314 8 14 18 172
            261 202 311 l23 37 367 4 c355 4 458 11 605 42 74 15 253 81 263 96 3 5 9 9
            14 9 5 0 -5 -30 -22 -67z"/>
            </g>
            </svg>
        </div>
    )
}

export default Logo;